//App.js
import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Intro from "./components/Intro";
import Footer2 from "./components/Footer2";
import Navbar from "./components/Navbar";
import AuditorRankings from "./components/AuditorRankings";
import SCU from "./components/SCU";
import WalletRisk from "./WalletRisk";
import Technician from "./components/Technician";
import Certifications from "./components/Certifications";
import NewQuiz from "./components/NewQuiz";

function App() {
  const [currentPage, setCurrentPage] = useState("home"); // 'home' is the default page

  const handlePageChange = (pageName) => {
    setCurrentPage(pageName);
  };

  const renderPage = () => {
    switch (currentPage) {
      case "auditorRankings":
        return <AuditorRankings />;
      case "scu":
        return <SCU />;
      case "walletRisk":
        return <WalletRisk />;
      case "Technician":
        return <Technician />;
      case "Certifications":
        return <Certifications />;
      case "NewQuiz":
        return <NewQuiz />;
      default:
        return <Intro />;
    }
  };

  const shouldDisplayFooter = currentPage === "home"; // Adjust based on your condition

  return (
    <div className="App">
      <Header />
      <Navbar onPageChange={handlePageChange} currentPage={currentPage} />
      <div className="wrapper">{renderPage()}</div>
      {shouldDisplayFooter && <Footer2 />}
    </div>
  );
}

export default App;
