import React, { useState, useEffect } from "react";

const questions = [
  {
    id: 1,
    question: "What is a smart contract?",
    type: "multi",
    options: [
      "A digital contract",
      "A paper contract",
      "A legal contract",
      "A blockchain contract",
    ],
    answer: "A blockchain contract",
  },
  {
    id: 2,
    question: "What does ERC-20 represent?",
    type: "input",
    answer: "Ethereum Request for Comments 20",
  },
  {
    id: 3,
    question: "What is gas in Ethereum?",
    type: "multi",
    options: ["Fuel for cars", "Network fees", "Gasoline", "Ether"],
    answer: "Network fees",
  },
  {
    id: 4,
    question: "What does DeFi stand for?",
    type: "input",
    answer: "Decentralized Finance",
  },
  {
    id: 5,
    question: "What is a blockchain?",
    type: "multi",
    options: [
      "A chain of blocks",
      "A digital ledger",
      "A type of cryptocurrency",
      "A software",
    ],
    answer: "A digital ledger",
  },
  {
    id: 6,
    question: "What is the native cryptocurrency of Ethereum?",
    type: "input",
    answer: "Ether",
  },
  {
    id: 7,
    question: "What is a wallet in the context of cryptocurrency?",
    type: "multi",
    options: [
      "A physical wallet",
      "A digital tool to store and manage cryptocurrencies",
      "A bank account",
      "A purse",
    ],
    answer: "A digital tool to store and manage cryptocurrencies",
  },
  {
    id: 8,
    question: "What is a DApp?",
    type: "input",
    answer: "Decentralized Application",
  },
  {
    id: 9,
    question:
      "What is the purpose of a whitepaper in a cryptocurrency project?",
    type: "multi",
    options: [
      "A document to detail the project's technology, purpose, and roadmap",
      "A type of paper",
      "A document for tax purposes",
      "A financial statement",
    ],
    answer:
      "A document to detail the project's technology, purpose, and roadmap",
  },
  {
    id: 10,
    question: "What does HODL mean in the crypto community?",
    type: "input",
    answer: "Hold On for Dear Life",
  },
];

const NewQuiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill(null));
  const [timer, setTimer] = useState(10);
  const [attempts, setAttempts] = useState(0);
  const [walletConnected, setWalletConnected] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      const timerId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      handleFail();
    }
  }, [timer]);

  const handleFail = () => {
    setAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers[currentQuestion] = "fail";
      return newAnswers;
    });
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setTimer(6);
    } else {
      handleQuizCompletion();
    }
  };

  const handleAnswer = (answer) => {
    setAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers[currentQuestion] = answer;
      return newAnswers;
    });
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setTimer(6);
    } else {
      handleQuizCompletion();
    }
  };

  const handleQuizCompletion = () => {
    const score =
      answers.filter((answer) => answer === "pass").length / questions.length;
    if (score >= 0.8) {
      alert("Quiz passed!");
    } else {
      setAttempts(attempts + 1);
      if (attempts < 2) {
        alert("Quiz failed. Try again.");
        setAnswers(Array(questions.length).fill(null));
        setCurrentQuestion(0);
        setTimer(6);
      } else {
        alert("Quiz failed. No more attempts allowed.");
      }
    }
  };

  const handleWalletConnect = async () => {
    // Add wallet connect logic here
    setWalletConnected(true);
  };

  if (!walletConnected) {
    return (
      <div className="intro-container">
        <p>
          To take the quiz, you must connect your wallet. You only get two
          tries, and the questions will be different each time.
        </p>
        <button className="wbtn" onClick={handleWalletConnect}>
          Connect Your Crypto Wallet
        </button>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      <div className="question-list">
        {questions.map((q, index) => (
          <div
            key={q.id}
            className={`question-item ${
              currentQuestion === index ? "active" : ""
            }`}
          >
            Question {index + 1}{" "}
            {currentQuestion === index && (
              <span className="timer">{timer}s</span>
            )}
          </div>
        ))}
      </div>
      <div className="question-content">
        <div className="question-text">
          {questions[currentQuestion].question}
        </div>
        {questions[currentQuestion].type === "multi" ? (
          <div className="options">
            {questions[currentQuestion].options.map((option) => (
              <button
                key={option}
                onClick={() =>
                  handleAnswer(
                    option === questions[currentQuestion].answer
                      ? "pass"
                      : "fail"
                  )
                }
              >
                {option}
              </button>
            ))}
          </div>
        ) : (
          <input
            type="text"
            onBlur={(e) =>
              handleAnswer(
                e.target.value === questions[currentQuestion].answer
                  ? "pass"
                  : "fail"
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default NewQuiz;
